import axios from "axios";

const BASE_URL = 'https://api.escoladenumerologia.com.br';

const api = () => {
  const instance = axios.create({
    baseURL: BASE_URL,     
    timeout: 60000,   
  });

  instance.interceptors.response.use(
    (response) => response,
    (err) => {
      if (err.response.status === 401) {
        throw new Error('unauthorized');
      }     

      if (err.response.data.error) {               
        throw new Error(err.response.data.error);        
      }
    },
  );

  return instance;
};

export default api();
