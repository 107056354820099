import { Outlet } from 'react-router-dom';
import ReactGA from 'react-ga4';

import './assets/styles/global.css';

const TRACKING_ID = 'G-RG132PZ0PF';
//const TRACKING_ID = 'aaa';
ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    <div id="app">     
      <Outlet />      
    </div>
  );
}

export default App;
