import { SxProps } from '@mui/system';

export const mainStyle: SxProps = {
  height: { 
    xs: '95.3rem',
    sm: '105.3rem',    
    lg: '75.3rem', 
  },  
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',      
  alignItems: 'center', 
};

export const contentStyle: SxProps = {
  width: '100%',
  height: {
    xs: '70rem',
    sm: '80rem',
    lg: '48rem',
  },
  display: 'flex',
  flexDirection: {
    xs: 'column',
    lg: 'row',
  },
  justifyContent: {
    xs: 'space-evenly',
    lg: 'center',
  },
  alignItems: 'center',  
};

export const textStyle: SxProps = {
  width: {
    xs: '30rem',
    sm: '38rem',
    lg: '45rem',   
  },
  height: {
    xs: '15rem',
    sm: '18rem',
    lg: '25rem',
  },
  marginRight: {
    lg: '3rem',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',  
}; 

export const titleStyle: SxProps = {
  fontSize: {
    xs: '3rem',
    sm: '3.5rem',
    lg: '4rem',
  },
  fontWeight: 600,
  fontFamily: 'barlow',
  textAlign: 'center',
  color: 'var(--color-text-secondary)',
  textDecorationColor: 'var(--color-text-secondary)',
  textDecorationLine: 'underline',   
}; 

export const ebookStyle: SxProps = {
  width: {
    xs: '30rem',
    sm: '35rem',
    lg: '40rem',
  },
  height: {
    xs: '40rem',
    sm: '46.66rem',
    lg: '53.33rem',
  },
  marginLeft: {
    lg: '3rem',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',  
};

export const strongStyle: SxProps = {
  fontSize: {
    xs: '2.5rem',
    sm: '3rem',
    lg: '4rem',
  },
  fontWeight: 600,
  fontFamily: 'barlow',
  color: 'var(--color-text-secondary)',
  textShadow: {
    xs: '1px 1px var(--color-text-secondary-shadow)',
    lg: '2px 2px var(--color-text-secondary-shadow)',
  },
  cursor: 'pointer',
  textAlign: 'start', 
};

export const imgStyle: SxProps ={
  width: '100%',
  height: '100%',
  cursor: 'pointer',
};

export const linkStyle: SxProps = {
  textDecoration: 'none',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',  
};

export const separatorStyle: SxProps = {
  width: '100%',
  height: {
    xs: '0.3rem',
    lg: '0.5rem',
  },  
  background: 'var(--color-background-light)',    
};

export const buttonStyle: SxProps = {
  width: {
    xs: '18rem',
    sm: '20rem',
    lg: '26rem',
  },
  height: {
    xs: '4rem',
    sm: '4.2rem',
    lg: '5rem',
  },
  background: 'var(--color-button)',
  color: 'var(--color-button-text)',
  border: 0,
  borderRadius: '4rem',  
  cursor: 'pointer',  
  fontSize: {
    xs: '2.2rem',
    sm: '2.4rem',
    lg: '3rem',
  },
  fontWeight: 600,
  fontFamily: 'barlow', 
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  transition: '0.2s',  
  '&:hover': { color: 'var(--color-button-text-hover)' },
  marginBottom: '5rem', 
};
