import { SxProps } from '@mui/system';

export const mainStyle: SxProps = {
  height: { 
    xs: '85rem',
    sm: '96rem',    
    lg: '75rem', 
  },  
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',      
  alignItems: 'center',  
};

export const contentStyle: SxProps = {
  width: '100%',
  height: {
    xs: '80rem',
    sm: '100rem',
    lg: '43rem',
  },
  display: 'flex',
  flexDirection: {
    xs: 'column',
    lg: 'row',
  },
  justifyContent: {
    xs: 'flex-end',
    lg: 'space-evenly',
    xl: 'center',
  },
  alignItems: {
    xs: 'center',
    lg: 'flex-end',
  },  
};

export const mainBlockStyle: SxProps = {
  display: 'flex',
  flexDirection: 'column',    
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: {
    xl: '3rem',
  },
  marginBottom: {
    xs: '5rem',
    lg: '6rem',
  }
};

export const separatorStyle: SxProps = {
  width: '100%',
  height: {
    xs: '0.3rem',
    lg: '0.5rem',
  },  
  background: 'var(--color-background-light)',    
};

export const imgJaimeStyle: SxProps = {
  width: {
    xs: '25rem',
    lg: '50rem',
  },  
  height: {
    xs: '30.86rem',
    lg: '61.72rem',
  },
  marginLeft: {
    xl: '3rem'
  }
};

export const textBlockStyle: SxProps = {
  width: {
    xs: '35rem',
    sm: '45rem',
    lg: '50rem',
    xl: '68rem',
  },  
  height: {
    xs: '30rem',
    sm: '40rem',
    lg: '45rem',
  },
  marginBottom: '2rem',
};

export const buttonStyle: SxProps = {
  width: {
    xs: '18rem',
    sm: '20rem',
    lg: '26rem',
  },
  height: {
    xs: '4rem',
    sm: '4.2rem',
    lg: '5rem',
  },
  background: 'var(--color-button)',
  color: 'var(--color-button-text)',
  border: 0,
  borderRadius: '4rem',  
  cursor: 'pointer',  
  fontSize: {
    xs: '2.2rem',
    sm: '2.4rem',
    lg: '3rem',
  },
  fontWeight: 600,
  fontFamily: 'barlow', 
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  transition: '0.2s',  
  '&:hover': { color: 'var(--color-button-text-hover)' },
  marginBottom: {
    xs: '3rem',
    sm: '4rem',
    lg: '6rem',
  }, 
};

export const fakeButtonStyle: SxProps = {
  width: {
    xs: '18rem',
    sm: '20rem',
    lg: '26rem',
  },
  height: {
    xs: '4rem',
    sm: '4.2rem',
    lg: '5rem',
  }, 
  marginBottom: {
    xs: '3rem',
    sm: '4rem',
    lg: '6rem',
  }, 
};

export const h2Style: SxProps = {
  fontSize: {
    xs: '1.9rem',
    sm: '2.5rem',
    lg: '2.8rem',
    xl: '3.2rem',
  },
  fontWeight: 500,
  fontFamily: 'barlow',
  color: 'var(--color-text-base)',
  textAlign: 'center',
  marginBottom: {
    xs: '1.2rem',
    sm: '1.6rem',
  },
};
