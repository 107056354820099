import { SxProps } from '@mui/system';

export const mainStyle: SxProps = {
  height: { 
    xs: '80.3rem',
    sm: '100.3rem',    
    lg: '43.3rem', 
  },  
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',      
  alignItems: 'center', 
};

export const contentStyle: SxProps = {
  width: '100%',
  height: {
    xs: '80rem',
    sm: '100rem',
    lg: '43rem',
  },
  display: 'flex',
  flexDirection: {
    xs: 'column',
    lg: 'row',
  },
  justifyContent: {
    xs: 'space-evenly',
    xl: 'center',
  },
  alignItems: 'center',  
};

export const cardStyle: SxProps = {
  width: {
    xs: '22rem',
    sm: '28rem',
    lg: '30rem',
  },
  height: {
    xs: '22rem',
    sm: '28rem',
    lg: '30rem',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',  
};

export const textStyle: SxProps = {
  width: {
    xs: '30rem',
    sm: '55rem',
    lg: '45rem',
    xl: '55rem',
  },
  height: {
    xs: '15rem',
    sm: '10rem',
  },
  marginTop: {
    lg: '10rem',
  },
  marginLeft: {
    xl: '5rem',
  },
  marginRight: {
    xl: '5rem',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',  
}; 

export const titleStyle: SxProps = {
  fontSize: {
    xs: '2rem',
    sm: '2.4rem',
  },
  fontWeight: 600,
  fontFamily: 'barlow',
  textAlign: 'center',
  color: 'var(--color-text-base)',  
}; 

export const logoStyle: SxProps = {
  width: {
    xs: '22rem',
    sm: '28rem',
    lg: '30rem',
  },
  height: {
    xs: '12.15rem',
    sm: '15.46rem',
    lg: '16.57rem',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: {
    xs: '3rem',
    lg: '5rem',
  }, 
};

export const logoTruenumbersStyle: SxProps = {
  width: {
    xs: '22rem',
    sm: '28rem',
    lg: '30rem',
  },
  height: {
    xs: '17.88rem',
    sm: '22.76rem',
    lg: '24.79rem',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '1rem',
};

export const h2Style: SxProps = {
  fontSize: {
    xs: '2rem',
    sm: '2.4rem',
  },
  fontWeight: 600,
  fontFamily: 'barlow',
  textAlign: 'center',
  color: 'var(--color-text-base)',  
};

export const strongStyle: SxProps = {
  fontSize: {
    xs: '1.7rem',
    sm: '2.4rem',
  },
  fontWeight: 600,
  fontFamily: 'barlow',
  color: 'var(--color-text-white)',
  textDecorationStyle: 'solid',
  textDecorationColor: 'var(--color-text-white)',
  textDecorationLine: 'underline',
  cursor: 'pointer', 
};

export const imgStyle: SxProps ={
  width: '100%',
  height: '100%',
  cursor: 'pointer',
};

export const linkStyle: SxProps = {
  textDecoration: 'none',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',  
};

export const separatorStyle: SxProps = {
  width: '100%',
  height: {
    xs: '0.3rem',
    lg: '0.5rem',
  },  
  background: 'var(--color-background-light)',    
};
