import React from 'react';

import useAnalyticsEventTracker from '../../utils/useAnalyticsEventTracker';

import emailIcon from '../../assets/images/icons/email.png';
import instagramIcon from '../../assets/images/icons/instagram.png';
import whatsappIcon from '../../assets/images/icons/whatsapp.png';
import youtubeIcon from '../../assets/images/icons/youtube.png';

import './styles.css';

const Footer: React.FC = () => {
  const gaEventTracker = useAnalyticsEventTracker('Contato');

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className='contacts'>        
          <div id="contato" className='contact'>
            <strong>Contato</strong>
            <p>Como nos encontrar</p>
          </div>
          <div className='contact'>
            <a href="https://escoladenumerologia.com.br/fale-conosco" target='_blank' rel='noreferrer noopener' onClick={()=> gaEventTracker.eventTracker('Visitar formulario contato', 'Visitar formulario contato')}>
              <img src={emailIcon} alt='icone_email'/>
            </a>
            <strong>Email</strong>   
          </div>
          <div className='contact'>
            <a href="https://wa.me/5513997573188" target='_blank' rel='noreferrer noopener' onClick={()=> gaEventTracker.eventTracker('Visitar whatsapp', 'Visitar whatsapp')}>
              <img src={whatsappIcon} alt='icone_whatsapp'/>
            </a>
            <strong>Whatsapp</strong>       
          </div>
          <div className='contact'>
            <a href='https://www.instagram.com/truenumbers_/' target='_blank' rel='noreferrer noopener' onClick={()=> gaEventTracker.eventTracker('Visitar instagram', 'Visitar instagram')}>
              <img src={instagramIcon} alt='icone_instagram'/>
            </a>
            <strong>Instagram</strong>
          </div>
          <div className='contact'>
            <a href='https://www.youtube.com/@_TRUENUMBERS' target='_blank' rel='noreferrer noopener' onClick={()=> gaEventTracker.eventTracker('Visitar youtube', 'Visitar youtube')}>
              <img src={youtubeIcon} alt='icone_youtube'/>
            </a>
            <strong>Youtube</strong>
          </div>
        </div>
        <strong>
          © 2023 | Truenumbers - Todos os Direitos Reservados
        </strong>        
      </div>
    </footer>
  );
}

export default Footer;
