import React, { useState } from 'react';
import Box from '@mui/system/Box';
import { motion } from 'framer-motion';

import useAnalyticsEventTracker from '../../utils/useAnalyticsEventTracker';

import jaime from '../../assets/images/jaime.png';

import {
  mainStyle,
  contentStyle,
  mainBlockStyle, 
  separatorStyle, 
  imgJaimeStyle,
  textBlockStyle,  
  buttonStyle,
  fakeButtonStyle,
  h2Style
} from './styles';

const FIRST = 'Essa é a história de Jaime Benedetti'
const SECOND = 'Espero que, de alguma maneira, ela tenha lhe inspirado e ajudado a refletir sobre suas questões pessoais.'
const THIRD = 'Boa parte das respostas que Jaime procurou, vieram da Numerologia.'
const FOURTH = 'Essa ferramenta incrível ajudou Jaime a entender sua jornada nesta existência, e a se relacionar com sabedoria com os seus desafios e aprendizados que a própria vida lhe brindou.'

const AnimatedText: React.FC = () => {
  const [ button, setButton ] = useState<boolean>(false);

  const gaEventTracker = useAnalyticsEventTracker('Jaime e a numerologia');

  const textChars1: any = FIRST.split('');
  const textChars2: any = SECOND.split('');
  const textChars3: any = THIRD.split('');
  const textChars4: any = FOURTH.split('')
  
  const charVariants = {
    hidden: { opacity: 0 },
    reveal: { opacity: 1 },
  };

  const handleShowButton = () => {
    setButton(true);
  };
  const handleHideButton = () => {
    setButton(false);
  };

  return (
    <Box sx={mainStyle}>
      <Box sx={contentStyle}>
        <Box sx={mainBlockStyle}>
          <Box sx={textBlockStyle}>
            <motion.h2              
              initial='hidden'
              whileInView='reveal'
              onAnimationStart={handleHideButton}            
              onAnimationComplete={handleShowButton}
              transition={{ staggerChildren: .05 }}              
            >
              <Box sx={h2Style}>
                {textChars1.map((char: any) => (              
                  <motion.span key={char} transition={{ duration: 1 }} variants={charVariants}>
                    {char}
                  </motion.span>             
                ))}
              </Box>
              <Box sx={h2Style}>
                {textChars2.map((char: any) => (              
                  <motion.span key={char} transition={{ duration: 1 }} variants={charVariants}>
                    {char}
                  </motion.span>             
                ))}
              </Box>
              <Box sx={h2Style}>
                {textChars3.map((char: any) => (              
                  <motion.span key={char} transition={{ duration: 1 }} variants={charVariants}>
                    {char}
                  </motion.span>             
                ))}
              </Box>
              <Box sx={h2Style}>
                {textChars4.map((char: any) => (              
                  <motion.span key={char} transition={{ duration: 1 }} variants={charVariants}>
                    {char}
                  </motion.span>             
                ))}
              </Box>    
            </motion.h2>
          </Box>          
          {
            button ?
              <Box component='a' sx={{textDecoration: 'none'}} href="https://www.escoladenumerologia.com.br" target='_blank' rel='noreferrer noopener'
                onClick={()=> gaEventTracker.eventTracker('Visitar escola de numerologia', 'Visitar escola de numerologia')}>
                <Box component='button' sx={buttonStyle}>SAIBA MAIS</Box>
              </Box>
            :
              <Box sx={fakeButtonStyle}/>
          }
        </Box>
        <Box component='img' src={jaime} alt='Foto_Jaime' sx={imgJaimeStyle} />        
      </Box>     
      <Box sx={separatorStyle} />
    </Box>
  );
};

export default AnimatedText;
