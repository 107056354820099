import './styles.css';

function Error404() {
  return (
    <header className="page-error">
      <div className="error-content">
        <h1>Error 404!</h1>
        <h3>Página não encontrada!</h3>       
      </div>
    </header>
  );
}

export default Error404;
