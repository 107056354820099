import React from "react";
import Box from '@mui/system/Box';

import useAnalyticsEventTracker from '../../utils/useAnalyticsEventTracker';

import ebook from '../../assets/images/ebook.png';

import {
  mainStyle,
  contentStyle,
  titleStyle,
  textStyle,
  ebookStyle,
  separatorStyle,
  imgStyle,
  linkStyle,
  buttonStyle,
  strongStyle,
} from './styles';

const Ebook: React.FC = () => {
  const gaEventTracker = useAnalyticsEventTracker('Livro e e-book');

  return (
    <Box sx={mainStyle}>
     <Box component='h2' sx={titleStyle}>LIVRO E E-BOOK</Box> 
     <Box sx={contentStyle}>
      <Box sx={textStyle}>
        <Box component='strong' sx={strongStyle}>DESCUBRA A JORNADA DA ALMA ENTRE REVIRAVOLTAS E REVELAÇÕES DIVINAS</Box>
      </Box>

      <Box sx={ebookStyle}>
        <Box component='img' sx={imgStyle} src={ebook} alt='ebook'/>
      </Box>
       
      </Box>
      <Box component='a' sx={linkStyle} href="https://clubedeautores.com.br/livros/autores/jaime-benedetti" target='_blank' rel='noreferrer noopener' 
        onClick={()=> gaEventTracker.eventTracker('Visitar clube dos autores', 'Visitar clube dos autores')}>
        <Box component='button' sx={buttonStyle}>ADQUIRA JÁ!</Box>
      </Box>
      <Box sx={separatorStyle} />
    </Box>
  );
};

export default Ebook;
