import Error404 from '../../components/Error404';

import './styles.css';

function ErrorPage() {
  return (
    <div id="error-page" className="container">
      <Error404 />
    </div>
  );
}

export default ErrorPage;
