import { useEffect } from 'react';

import useAnalyticsEventTracker from '../../utils/useAnalyticsEventTracker';

import Main from '../../components/Main';
import AnimatedText from '../../components/AnimatedText';
import Ebook from '../../components/Ebook';
import Sponsor from '../../components/Sponsor';
import Footer from '../../components/Footer';

import './styles.css';

function Landing() {

  const gaEventTracker = useAnalyticsEventTracker('Página principal');

  useEffect(() => {
    gaEventTracker.sendPageView('/', 'Página principal');
  }, []);

  return (
    <div id="landing-page" className="container">
      <Main />
      <AnimatedText />
      <Ebook />
      <Sponsor />
      <Footer />
    </div>
  );
}

export default Landing;
