import React from 'react';
import Box from '@mui/system/Box';

import useAnalyticsEventTracker from '../../utils/useAnalyticsEventTracker';

import logoEscola from '../../assets/images/logos/logo_escola.png';
import LogoTruenumbers from '../../assets/images/logos/logo_truenumbers.png';

import {
  mainStyle,
  contentStyle,
  cardStyle,
  textStyle,
  titleStyle,
  logoStyle,
  logoTruenumbersStyle,
  separatorStyle,
  h2Style,
  imgStyle,
  linkStyle,
} from './styles';

const Sponsor: React.FC = () => {  
  const gaEventTracker = useAnalyticsEventTracker('Apoio');

  return (
    <Box sx={mainStyle}>
      <Box sx={contentStyle}>
        
        <Box sx={cardStyle}>
          <Box component='h3' sx={titleStyle}>PATROCÍNIO</Box>
          <Box component='a' sx={linkStyle} href="https://www.truenumbers.com.br" target='_blank' rel='noreferrer noopener'
            onClick={()=> gaEventTracker.eventTracker('Visitar truenumbers', 'Visitar truenumbers')}>
            <Box sx={logoTruenumbersStyle}>
              <Box component='img' sx={imgStyle} src={LogoTruenumbers} alt='logo_truenumbers'/>
            </Box>
          </Box>          
        </Box>
        
        <Box sx={textStyle}>
          <Box component='h2' sx={h2Style}>A BUSCA DO AUTOCONHECIMENTO E O DESENVOLVIMENTO DA CONSCIÊNCIA HUMANA</Box>
        </Box>

        <Box sx={cardStyle}>
          <Box component='h3' sx={titleStyle}>APOIO</Box>
          <Box component='a' sx={linkStyle} href="https://www.escoladenumerologia.com.br" target='_blank' rel='noreferrer noopener'
            onClick={()=> gaEventTracker.eventTracker('Visitar escola de numerologia', 'Visitar escola de numerologia')}>
            <Box sx={logoStyle}>
              <Box component='img' sx={imgStyle} src={logoEscola} alt='logo_escola'/>
            </Box>
          </Box>
        </Box>

      </Box>      
      <Box sx={separatorStyle} />
    </Box>
  );
};

export default Sponsor;
